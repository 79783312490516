import React from 'react'
import Header from '../components/header/Header'
import Info from '../components/info/Info1Win'
import Footer from '../components/footer/Footer'

const Info1win = () => {
  return (
    <>
    <Header/>
    <Info/>
    <Footer/>
    </>
  )
}

export default Info1win