import React from 'react'
import Header from "../components/header/Header";
import InfoS from "../components/reviews/ReviewsInfo";
import Footer from "../components/footer/Footer";

const Reviews = () => {
  return (
    <>
    <Header/>
    <InfoS/>
    <Footer/>
    </>
  )
}

export default Reviews