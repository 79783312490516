import React from "react";
import Header from "../components/header/Header";
import Info from "../components/info/Info";
import Pics from "../components/pics/Pics";
import Footer from "../components/footer/Footer";

const HomePage = () => {
  return (
    <>
    <Header/>
    <Info/>
    <Pics/>
    <Footer/>
    </>
  );
};

export default HomePage;
