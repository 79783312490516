import React from "react";
import Header from "../components/header/Header";
import InfoS from "../components/info/InfoS";
import Footer from "../components/footer/Footer";

const Strategy = () => {
  return (
    <>
    <Header/>
    <InfoS/>
    <Footer/>
    </>
  );
};

export default Strategy;
