import React from 'react'
import Header from "../components/header/Header";
import InfoD from "../components/info/InfoD";
import Footer from "../components/footer/Footer";
const Download = () => {
  return (
    <>
    <Header/>
    <InfoD/>
    <Footer/>
    </>
  )
}

export default Download