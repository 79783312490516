import React, { useEffect, useState } from "react";
import "./Footer.scss";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60 * 60); // Update every hour

    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="footer">
      <p>Gates of Olympus © {currentYear}</p>
    </div>
  );
};

export default Footer;
