import React from "react";
import "./Info.scss";
import img from "../../assets/pinup1.webp";
import img2 from "../../assets/pinup1.jpg";
import img3 from "../../assets/pinup3.webp";

const InfoPin = () => {
  return (
    <>
      <div className="right-image center">
        <div className="text-box center">
          <h1>Play Gates Of Olympus in PinUp casino</h1>
          <p>
            Welcome to the world of exciting excitement and unlimited
            possibilities! If you are looking for an exciting slot machine that
            will immerse you in the world of ancient Greek mythology, then the
            Gates of Olympus at the Pin-Up online casino is your perfect choice!
          </p>
          <p>
            A delightful journey into the world of the Olympic gods The Gates of
            Olympus is a game that takes you into the magical reality of ancient
            Greece, where you will find yourself among the powerful Olympian
            gods. Imagine the greatness of Zeus, the beauty of Athena and the
            power of Poseidon shining on the reels of this exciting slot. The
            graphics and soundtrack immerse you in the atmosphere of mythology,
            creating a unique gaming atmosphere.
          </p>
          <p>
            Exciting gameplay and rich winning opportunities The Gates of
            Olympus offer not only aesthetic pleasure, but also generous winning
            opportunities. Combine the symbols of gods and heroes, activate
            bonus rounds and free spins, and you can win big prizes. The high
            percentage of returns makes this slot especially attractive for all
            gamblers.
          </p>
          <div className="button-large center">
            <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
              <button className="button-27">
                Play Gates Of Olympus in PinUp
              </button>
            </a>
          </div>
        </div>
        <div className="image-container center">
          <img src={img} alt="" />
        </div>
      </div>
      <div className="text-box-big center">
        <h1>Registration in 1xBet casion for Gates Of Olympus</h1>
        <img className="big-img" src={img2} alt="" />
        <p>
          Do you want to plunge into the world of gods and heroes and try your
          luck in the game "Gates of Olympus" on Pin-Up? No problem!
          Registration on our website is quick and easy. Follow these simple
          steps to start your exciting gaming journey:
        </p>
        <ul className="number-list-i">
          <li>
            Open the official Pin-Up website. There will be two options
            available to you: playing in the browser or downloading a mobile
            app. Select the appropriate option and proceed to the next step.
          </li>
          <li>
            Click on the "Register" or "Register" button. This will open a
            registration form where you will need to enter some information.
          </li>
          <li>
            Fill in all the required fields. You will need to enter your
            personal information, such as first name, last name, date of birth,
            email address and phone number. Make sure you enter the correct data
            to avoid problems in the future.
          </li>
          <li>
            Create a unique username and password. They will be used to log in
            to the Pin-Up site in the future. It is recommended to use a strong
            password consisting of a combination of letters, numbers and
            symbols.
          </li>
          <li>
            Confirm your registration. A confirmation email or SMS message may
            be sent to you. Just follow the instructions in the message to
            complete the registration process.
          </li>
        </ul>
        <p>
          Congratulations! Now you have a registered Pin-Up account and you are
          ready to immerse yourself in the exciting world of the Gates of
          Olympus. Top up your account, choose a slot and enjoy the game with a
          chance to win big prizes!
        </p>
        <div className="button-large center">
          <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button className="button-27">
              Play Gates Of Olympus in PinUp
            </button>
          </a>
        </div>
      </div>
      <div className="text-box-pig center">
        <h1>Advantages of PinUp for playing Gates of Olympus</h1>
        <img className="big-img" src={img3} alt="" />
        <p>
          Playing the Gates of Olympus at the Pin-Up online casino is not only
          an exciting journey into the world of ancient Greek mythology, but
          also an opportunity to enjoy many advantages. That's why Pin-Up is the
          perfect choice for players who want to try their luck at the Olympus
          Gate slot.
        </p>
        <ul className="list-cir-o">
          <li>
            Generous bonuses and Promotions: Pin-Up takes care of its players
            and offers generous bonuses and promotions. Upon registration, you
            will receive a welcome bonus, and then you will receive regular
            promotions, tournaments and gifts. This gives you a better chance of
            winning and allows you to extend the game time.
          </li>
          <li>
            Variety of games: Pin-Up Casino offers a huge selection of gambling
            games, including the Gates of Olympus. You can enjoy this exciting
            slot and also try many other games including slots, roulette,
            blackjack and more. We have games for every taste and preference.
          </li>
          <li>
            Convenience and accessibility: With Pin-Up, you can play at any
            convenient time and place. Our casino is available on both computer
            and mobile devices, which allows you to enjoy the game at home or on
            the go. We have created an intuitive interface to make your game as
            comfortable as possible.
          </li>
          <li>
            Safety and Reliability: We understand that safety is important for
            our players. Therefore, we use the most advanced data protection
            technologies to ensure the security of your personal and financial
            data. You can play on our platform with confidence and peace of
            mind.
          </li>
          <li>
            Professional Support: We have a team of professionals who are ready
            to help you with any questions or problems you may encounter. Our
            support team is available 24/7 and we guarantee prompt response to
            your requests.|
          </li>
          <li>
            Do not miss the opportunity to take advantage of the online Pin-Up
            casino when playing Olympus Gate. Here you will find everything you
            need for an exciting and profitable gaming experience. Join us today
            and enjoy the following benefits:
          </li>
          <li>
            Fast and secure financial transactions: We offer a wide range of
            popular payment methods, which makes the process of depositing and
            withdrawing winnings simple and convenient. All financial
            transactions are processed using reliable data protection systems,
            ensuring complete confidentiality and security of your financial
            transactions.
          </li>
          <li>
            Loyalty Program and VIP Privileges: We value our players and offer a
            loyalty program that rewards you for being active and consistent. In
            addition, we have a VIP club where our most dedicated players can
            enjoy special privileges, bonuses and personalized service.
          </li>
          <li>
            Availability in different languages: We strive to provide maximum
            comfort to players from all over the world. Our website and support
            service are available in various languages, including Russian. This
            way you can enjoy the game and get help in your native language.
          </li>
          <li>
            Licensed Operator: Pin-Up is a licensed online casino, which means
            that we meet the high standards of the supervisory authorities. This
            gives you a guarantee that you are playing in an honest and reliable
            environment where your safety and interests are protected.
          </li>
        </ul>
        <p>
          Do not miss the opportunity to plunge into the exciting world of the
          "Gates of Olympus" together with the Pin-Up online casino. Register
          now, get a welcome bonus and enjoy the unique gaming experience that
          we are ready to offer you!
        </p>
        <div className="button-large center">
          <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button className="button-27">
              Play Gates Of Olympus in PinUp
            </button>
          </a>
        </div>
      </div>
    </>
  );
};

export default InfoPin;
