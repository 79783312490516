import React from "react";
import "./Info.scss";
import img from "../../assets/1win1.png";
import img2 from "../../assets/1win2.jpg";
import img3 from "../../assets/1win3.jpg";

const Info1Win = () => {
  return (
    <>
      <div className="right-image center">
        <div className="text-box center">
          <h1>Play Gates Of Olympus in 1win casino</h1>
          <p>
            Online gambling is becoming an increasingly popular entertainment,
            and when it comes to casinos, 1win is one of the most attractive
            places to play. One of the most exciting and popular slots offered
            by this casino is "Gates of Olympus". This game immerses players in
            the world of ancient Greek mythology and offers exciting prizes and
            winning opportunities.
          </p>
          <p>
            "Gates of Olympus" is a slot with five reels and various symbols
            associated with Greek mythology. Majestic graphics and impressive
            animations create the atmosphere of a mythical Olympus. In addition,
            the game has various bonus features such as free spins, multipliers
            and special symbols that can help you increase your winnings.
          </p>
          <p>
            1win offers a convenient and secure platform for playing "Gates of
            Olympus". You can easily access the game via a web browser on your
            computer or mobile device. The casino also ensures the protection of
            your personal data and financial transactions using modern
            encryption technologies.
          </p>
          <p>
            If you are looking for an exciting slot with an interesting theme
            and high chances of winning, "Gates of Olympus" in 1win is a great
            choice for you. Sign up for 1win, immerse yourself in the world of
            ancient Greek mythology and try your luck in this exciting game.
          </p>
          <p>
            <br />
          </p>
          <div className="button-large center">
            <a href="https://1wauah.xyz/#79x5" target="_blank">
              <button className="button-26">
                Play Gates Of Olympus in 1win
              </button>
            </a>
          </div>
        </div>
        <div className="image-container center">
          <img src={img} alt="" />
        </div>
      </div>
      <div className="text-box-big center">
        <h1>Registration in 1win casion for Gates Of Olympus</h1>
        <img className="big-img" src={img2} alt="" />
        <p>
          If you want to try your luck at the Gates of Olympus game on the 1 win
          website, you will need to register. The registration process on the
          1win website is simple and convenient. Here are a few steps that you
          need to follow:
        </p>
        <ul className="number-list">
          <li>
            Go to the official website of 1 win. On the main page you will see
            the "Register" or "Register" button. Click on it.
          </li>
          <li>
            In the window that opens, you will be asked to fill out a small
            registration form. Enter your personal information such as first
            name, last name, email address and phone number. Make sure that you
            provide reliable information.
          </li>
          <li>
            Accept the terms of Use and Privacy policy. Be sure to read this
            information to be aware of the rules and restrictions of the
            platform.
          </li>
          <li>
            Click the "Register" or "Continue" button to complete the
            registration process. Your account on the 1 win website will be
            created.
          </li>
        </ul>
        <p>
          After completing the registration, you will be able to log into your
          account, top up your balance and start playing Gates of Olympus and
          other exciting games offered by 1win.
        </p>
        <div className="button-large center">
          <a href="https://1wauah.xyz/#79x5" target="_blank">
            <button className="button-26">Play Gates Of Olympus in 1win</button>
          </a>
        </div>
      </div>
      <div className="text-box-pig center">
        <h1>Registration in 1win casion for Gates Of Olympus</h1>
        <img className="big-img" src={img3} alt="" />
        <p>
          1 win is a popular online casino that offers players a number of
          advantages. One of the main advantages for fans of Gates of Olympus is
          a wide selection of slots and a variety of gaming options. Here you
          will find a variety of themed slots with fascinating plots, attractive
          graphics and impressive bonuses.
        </p>
        <p>
          Another advantage of 1 win is the security and reliability of the
          platform. The casino operates under a license, which guarantees the
          honesty and continuity of the game. Your personal data and financial
          information are kept safe thanks to reliable encryption systems.
        </p>
        <p>
          In addition, 1 win offers various bonuses and promotions for "Gates of
          Olympus" players. A welcome bonus is available to new participants,
          which allows them to increase their starting bankroll. There are also
          regular promotions, tournaments and loyalty programs that allow you to
          get additional benefits and opportunities to win.
        </p>
        <div className="button-large center">
          <a href="https://1wauah.xyz/#79x5" target="_blank">
            <button className="button-26">Play Gates Of Olympus in 1win</button>
          </a>
        </div>
      </div>
    </>
  );
};

export default Info1Win;
