import React from "react";
import "./Pics.scss";
import img from "../../assets/new3.webp";
import img2 from "../../assets/new2.jpg";
import img3 from "../../assets/new1.jpg";
import img4 from "../../assets/new7.jpg";
import img5 from "../../assets/new6.webp";
import img6 from "../../assets/new5.jpg";
import img7 from "../../assets/new4.webp";
import img8 from "../../assets/new3.webp";
import img9 from "../../assets/new2.jpg";
import img10 from "../../assets/new1.jpg";
import img11 from "../../assets/new6.webp";
import img12 from "../../assets/new5.jpg";
import img13 from "../../assets/new4.webp";
import img14 from "../../assets/new3.webp";
import img15 from "../../assets/new2.jpg";
import img16 from "../../assets/new1.jpg";

const Pics = () => {
  return (
    <div className="table-container center">
      <h1>Gates Of Olympus Game</h1>
      <div className="row ">
        <div className="column center">
          <h3>Gates Of Olympus Game</h3>
          <img src={img} alt="" />
        </div>
        <div className="column center">
          <h3>Gates Of Olympus Official Web Site</h3>
          <img src={img2} alt="" />
        </div>
        <div className="column center">
          <h3>Gates Of Olympus Official Game Site</h3>
          <img src={img3} alt="" />
        </div>
        <div className="column center">
          <h3>Gates Of Olympus</h3>
          <img src={img4} alt="" />
        </div>
        <div className="column center">
          <h3>Play Gates Of Olympus for money</h3>
          <img src={img5} alt="" />
        </div>
        <div className="column center">
          <h3>Play Gates Of Olympus Demo</h3>
          <img src={img6} alt="" />
        </div>
        <div className="column center">
          <h3>Play Gates Of Olympus Official Free</h3>
          <img src={img7} alt="" />
        </div>
        <div className="column center">
          <h3>Gates Of Olympus Registration</h3>
          <img src={img8} alt="" />
        </div>
        <div className="column center">
          <h3>Registration Gates Of Olympus</h3>
          <img src={img9} alt="" />
        </div>
        <div className="column center">
          <h3>Gates Of Olympus 1win</h3>
          <img src={img10} alt="" />
        </div>
        <div className="column center">
          <h3>Gates Of Olympus Official 1xBet</h3>
          <img src={img11} alt="" />
        </div>
        <div className="column center">
          <h3>Gates Of Olympus Pin-Up</h3>
          <img src={img12} alt="" />
        </div>
        <div className="column center">
          <h3>Gates Of Olympus Strategy</h3>
          <img src={img13} alt="" />
        </div>
        <div className="column center">
          <h3>Gates Of Olympus Tactics</h3>
          <img src={img14} alt="" />
        </div>
        <div className="column center">
          <h3>Download Game Gates Of Olympus</h3>
          <img src={img15} alt="" />
        </div>
        <div className="column center">
          <h3>Download Application Gates Of Olympus</h3>
          <img src={img16} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Pics;
