import React from "react";
import "./Info.scss";
import img from "../../assets/1win1.png";
import img2 from "../../assets/new1.jpg";
import img3 from "../../assets/new3.webp";
import img4 from "../../assets/new6.webp";
import img5 from "../../assets/new7.jpg";
import img6 from "../../assets/new4.webp";
import img7 from "../../assets/new5.jpg";
import img8 from "../../assets/new6.webp";
import img9 from "../../assets/new1.jpg";

const Info = () => {
  return (
    <div>
      <div className="right-image center">
        <div className="text-box center">
          <h1>What is Gates Of Olypmus?</h1>
          <p>
            Gates of Olympus is an exciting online slot from the Pragmatic Play
            company. This game will allow you to immerse yourself in the world
            of Greek mythology and feel like you are on Olympus with the ancient
            gods.
          </p>
          <p>
            Gates of Olympus runs on the PayAnywhere engine, which means that
            winnings occur when 8 to 12+ symbols match on one spin. The game is
            replete with bright symbols and sound effects, which makes the
            gameplay really exciting.
          </p>
          <p>
            Among the main characters of the game is Zeus himself, who can offer
            you various bonuses and increase your chances of winning. This game
            will definitely give you a lot of positive emotions and
            opportunities to win!
          </p>
          <p>
            So, if you love gambling and Greek mythology, Gates of Olympus is
            what you need. Join the great gods of Olympus and try your luck in
            this unique slot!
          </p>
          <div className="buttons center">
            <a href="https://1wauah.xyz/#79x5" target="_blank">
              <button className="button-26">
                Play Gates Of Olympus in 1win
              </button>
            </a>

            <br />
            <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
              <button className="button-26">
                Play Gates Of Olympus in Pin-Up
              </button>
            </a>
            <br />
            <a href="https://5yyt5ycllb1wx5gmst.com/0whs" target="_blank">
              <button className="button-26">
                Play Gates Of Olympus in Mostbet
              </button>
            </a>
          </div>
        </div>
        <div className="image-container center">
          <img src={img} alt="" />
        </div>
      </div>
      <div className="right-image center">
        <div className="image-container center">
          <img src={img2} alt="" />
        </div>
        <div className="text-box center">
          <h1>What distinguishes the Gates of Olympus slot from others? </h1>
          <p>
            Gates of Olympus has several unique features that make this slot
            special and unforgettable.
          </p>
          <div>
            <strong>Pay Anywhere Game Engine </strong>
          </div>
          <br />
          <p>
            One of the features of Gates of Olympus is the Pay Anywhere game
            engine. It means that you win when 8 to 12+ characters match
            anywhere on the screen. This adds a new level of excitement and
            excitement to the game, opening up more opportunities to win.
          </p>
          <div>
            <strong>Multipliers and bonus rounds</strong>
          </div>
          <br />
          <p>
            Gates of Olympus has random multipliers and bonus rounds that can
            increase your winnings significantly. Zeus, soaring majestically on
            the right, can trigger the multiplier function, bringing multipliers
            from 2x to 500x. This unique feature makes each spin more exciting
            and unpredictable.
          </p>
          <div>
            <strong>The theme of Greek mythology</strong>
          </div>
          <br />
          <p>
            Gates of Olympus is also unique in its theme. Here, players can
            experience the atmosphere of Greek mythology, meet Zeus and other
            gods. This gives the game a special atmosphere and allows players to
            travel back to the era of ancient Greece.
          </p>
          <p>
            All in all, Gates of Olympus is a unique slot offering players
            unique opportunities and unforgettable experiences. Join us and try
            your hand against the gods of Olympus!
          </p>
        </div>
      </div>
      <div className="text-box-big center">
        <h1>Features of the Gates of Olympus slot? </h1>
        <img src={img3} alt="" />
        <p>
          The Gates of Olympus slot, developed by Pragmatic Play, has many
          interesting features that make it attractive to a wide range of
          players.
        </p>
        <ul>
          <li>The Play Anywhere function </li>
          <p>
            The Pay Anywhere engine is a key feature of this slot. You win when
            8 to 12+ symbols match on the screen, regardless of their location.
            This adds an element of unpredictability and exciting excitement.
          </p>
          <li>The Ante Bet function </li>
          <p>
            The slot offers an Ante Bet option, which increases the value of
            each spin by 25%, but doubles your chances of getting a bonus round.
            This is especially useful for players who are looking for big wins.
          </p>
          <li>The Tumble function</li>
          <p>
            After each winning spin, the symbols that formed the winnings
            disappear, and new symbols fall in their place, which opens up
            opportunities for additional winnings.
          </p>
          <li>Multiplier function</li>
          <p>
            The random multiplier function can be activated by Zeus at any time.
            When it is activated, colored stones with multipliers from 2x to
            500x appear on the reels, which add up and increase your total
            winnings.
          </p>
          <li>Bonus Round</li>
          <p>
            The bonus round is triggered when at least 4 Zeus symbols appear on
            the reels. In this round, the player is given 15 free spins and the
            opportunity to increase winnings due to a progressive multiplier,
            which is not reset after each spin.
          </p>
          <li>The possibility of buying a bonus</li>
        </ul>
        <p>
          For players living outside the UK, there is an option to purchase a
          bonus round for 100x of your bet. This can be a great way to increase
          your chances of winning big.
        </p>
        <p>
          Gates of Olympus slot machine is a slot filled with unique features,
          exciting gameplay and excellent graphics. Join us on a trip to Olympus
        </p>
        <div className="buttons-center center">
          <a href="https://1wauah.xyz/#79x5" target="_blank">
            <button className="button-26">Play Gates Of Olympus in 1win</button>
          </a>

          <br />

          <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button className="button-26">
              Play Gates Of Olympus in Mostbet
            </button>
          </a>
          <br />
          <a href="https://5yyt5ycllb1wx5gmst.com/0whs" target="_blank">
            <button className="button-26">
              Play Gates Of Olympus in Mostbet
            </button>
          </a>
        </div>
      </div>
      <div className="right-image center">
        <div className="text-box center">
          <h1>The rules of the Gates of Olympus slot game </h1>
          <p>
            Playing the Gates of Olympus slot is quite simple. Your goal is to
            get 8 to 12+ matching characters on the screen. Due to the Play
            Anywhere engine, these symbols can be located anywhere on the
            screen.
          </p>
          <p>
            There are two main game modes - the main mode and the bonus round.
            In the main mode, your goal is to get winning combinations of
            symbols and collect multipliers that fall out randomly.
          </p>
          <p>
            The bonus round starts when you get 4 or more Zeus symbols on the
            reels. In this mode, you are given 15 free spins and all the
            multipliers that you get during these spins are added up and not
            reset after each spin.
          </p>
          <p>
            It is important to remember that the winnings depend on the bet you
            have made. At Gates of Olympus, you can win up to 5,000x your bet.
          </p>
        </div>
        <div className="image-container center">
          <img src={img4} alt="" />
        </div>
      </div>
      <div className="text-box-big center">
        <h1>Which casinos offer the Gates of Olympus slot?</h1>
        <img src={img5} alt="" />
        <p>
          Gates of Olympus is a slot that can be enjoyed at many famous online
          casinos. Below are some of them:
        </p>
        <ul>
          <li>1win</li>
          <p>
            1win is one of the most popular casinos that offers its players an
            extensive selection of games, including Gates of Olympus. Here you
            will find both classic slots and new games from leading developers.
            No matter if you are a beginner or an experienced player, you will
            definitely find something to your liking.
          </p>

          <li> Pin-Up</li>
          <p>
            Pin-Up is another casino where you can enjoy Gates of Olympus. This
            casino offers many attractive bonuses and promotions, which makes
            the game even more exciting. Regardless of whether you play on a
            desktop or mobile device, the quality of the game remains at a high
            level.
          </p>
          <li> Mostbet </li>
          <p>
            Mostbet is another place where you can find the Gates of Olympus
            slot. This casino is known for its extensive range of games and high
            level of customer service. You will definitely enjoy the unique
            experience they offer.
          </p>
          <p>
            Remember that when playing at an online casino, it is important to
            keep an eye on your budget and play responsibly. Good luck on the
            Gates of Olympus reels!
          </p>
        </ul>
        <div className="buttons-center center">
          <a href="https://1wauah.xyz/#79x5" target="_blank">
            <button className="button-26">Play Gates Of Olympus in 1win</button>
          </a>

          <br />

          <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button className="button-26">
              Play Gates Of Olympus in Mostbet
            </button>
          </a>
          <br />
          <a href="https://5yyt5ycllb1wx5gmst.com/0whs" target="_blank">
            <button className="button-26">
              Play Gates Of Olympus in Mostbet
            </button>
          </a>
        </div>
      </div>
      <div className="right-image center">
        <div className="image-container center">
          <img src={img6} alt="" />
        </div>
        <div className="text-box center">
          <h1>On which devices can I play the Gates of Olympus slot? </h1>
          <p>
            Today, the opportunity to play at an online casino is offered for a
            wide range of devices. The answer to the question of which devices
            can be used to play the Gates of Olympus slot will be very
            encouraging - on almost any!
          </p>
          <p> Desktop computers and laptops </p>
          <p>
            The first thing that comes to mind is, of course, desktop computers
            and laptops. Playing on the big screen gives you a better overview
            and a deeper immersion in the gameplay. Gates of Olympus is
            perfectly displayed on large screens and offers vivid graphics and
            dynamic gameplay.
          </p>
          <p> Smartphones and tablets </p>
          <p>
            However, in the era of mobile technology, most people prefer to play
            casino games on their smartphones or tablets. The Gates of Olympus
            slot is fully adapted for mobile devices. The game functions
            seamlessly on Android and iOS, so you can play anywhere with an
            internet connection. Enjoy the bright colors and exciting gameplay
            right in the palm of your hand!
          </p>
          <p> Playing through a browser or an app </p>
          <p>
            If you prefer to play through the browser, most casinos, including
            1win, 1xBet, Pin-Up and Mostbet, offer this option. Just open the
            casino website in your device's browser and find the Gates of
            Olympus slot.
          </p>
          <p>
            If you like the convenience of using applications, many casinos also
            offer their own mobile applications that you can download and
            install on your device. These apps usually offer a full range of
            casino games, including Gates of Olympus.
          </p>
          <p>
            Regardless of the device you choose or the way you play, it is
            important to keep safety in mind and play responsibly. May luck
            accompany you to the Gates of Olympus!
          </p>
        </div>
      </div>
      <div className="text-box-big center">
        <h1>
          FAQ - Frequently Asked Questions about the Gates of Olympus game
        </h1>
        <img src={img7} alt="" />
        <h3>What is the Gates of Olympus slot? </h3>
        <p>
          Gates of Olympus is an online slot developed by Pragmatic Play. It is
          inspired by Greek mythology and offers unique gaming features such as
          PayAnywhere and random multipliers from Zeus.
        </p>
        <h3>Is there a bonus game in the Gates of Olympus slot? </h3>
        <p>
          Yes, I have. The Gates of Olympus bonus round is triggered when you
          hit at least 4 Zeus icons (Scatter) in one spin. The bonus round
          offers 15 free spins.
        </p>
        <h3>What is a risk game in the Gates of Olympus slot? </h3>
        <p>
          At the moment, there is no information about the risk game in Gates of
          Olympus.
        </p>
        <h3>What is the minimum bet in the Gates of Olympus slot? </h3>
        <p>
          The minimum bet at Gates of Olympus is usually 0.20 units of currency,
          but this may vary depending on the casino you choose.
        </p>
        <h3>What is the maximum bet in the Gates of Olympus slot? </h3>
        <p>
          The maximum bet at Gates of Olympus is usually 100 units of currency,
          but this may also vary from casino to casino.
        </p>
        <h3>
          What is the volatility of the game in the Gates of Olympus slot?
        </h3>
        <p>
          The volatility in Gates of Olympus is high, which means that the
          winnings may be large, but they appear less frequently.
        </p>
        <h3>What is the percentage of return in the Gates of Olympus slot? </h3>
        <p>
          Gates of Olympus slot offers a return of bets to the player (RTP) at
          96.5%, which is higher than the industry average.
        </p>
        <h3>What is the Wild symbol in the Gates of Olympus slot? </h3>
        <p>There is no Wild symbol in the Gates of Olympus slot.</p>
        <h3>What is the Scatter symbol in the Gates of Olympus slot? </h3>
        <p>
          The Scatter symbol in Gates of Olympus is the Zeus icon. Getting at
          least 4 Zeus icons triggers the bonus round.
        </p>
        <h3>
          Is it possible to play the Gates of Olympus slot in automatic mode?{" "}
        </h3>
        <p>
          Yes, Gates of Olympus offers the possibility of an automatic game.
        </p>
        <h3>Is it possible to play Gates of Olympus slot for free? </h3>
        <p>
          Yes, many casinos offer the opportunity to play Gates of Olympus for
          free in demo mode.
        </p>
        <div className="buttons-center center">
          <a href="https://1wauah.xyz/#79x5" target="_blank">
            <button className="button-26">Play Gates Of Olympus in 1win</button>
          </a>

          <br />

          <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button className="button-26">
              Play Gates Of Olympus in Mostbet
            </button>
          </a>
          <br />
          <a href="https://5yyt5ycllb1wx5gmst.com/0whs" target="_blank">
            <button className="button-26">
              Play Gates Of Olympus in Mostbet
            </button>
          </a>
        </div>
      </div>
      <div className="text-box-big center">
        <h1>
          How do I deposit to my account to play the Gates of Olympus slot?
        </h1>
        <img src={img8} alt="" />
        <p>
          Adding funds to your account to play the Gates of Olympus slot is a
          simple and secure process. First of all, you need to choose an online
          casino that offers this game. After registration, you go to the "Bank"
          or "Cashier" section on the casino's website.
        </p>
        <p>
          Then select your preferred payment method. Most casinos offer various
          ways to top up your account, including bank cards, e-wallets,
          cryptocurrencies and others. After selecting the payment method, enter
          the amount you want to deposit and confirm the transaction. Your
          account will be instantly replenished and you can start playing Gates
          of Olympus.
        </p>
        <div className="buttons-center center">
          <a href="https://1wauah.xyz/#79x5" target="_blank">
            <button className="button-26">Play Gates Of Olympus in 1win</button>
          </a>

          <br />

          <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button className="button-26">
              Play Gates Of Olympus in Mostbet
            </button>
          </a>
          <br />
          <a href="https://5yyt5ycllb1wx5gmst.com/0whs" target="_blank">
            <button className="button-26">
              Play Gates Of Olympus in Mostbet
            </button>
          </a>
        </div>
      </div>
      <div className="right-image center">
        <div className="text-box center">
          <h1>Demo version of the Gates of Olympus game </h1>
          <p>
            If you want to try Gates of Olympus before playing for real money,
            you can use the demo version of the game. This is a great
            opportunity to get to know the gameplay, features and symbols
            without risking your money.
          </p>
          <p>
            If you want to try Gates of Olympus before playing for real money,
            you can use the demo version of the game. This is a great
            opportunity to get to know the gameplay, features and symbols
            without risking your money.
          </p>
          <p>
            To access the demo version, simply select Gates of Olympus on the
            website of your chosen casino and click the "Demo" or "Practice"
            button. Keep in mind that in some cases you may need to create an
            account even if you want to play the demo version.
          </p>
          <div className="buttons center">
            <a href="https://1wauah.xyz/#79x5" target="_blank">
              <button className="button-26">
                Play Gates Of Olympus in 1win
              </button>
            </a>

            <br />
            <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
              <button className="button-26">
                Play Gates Of Olympus in Pin-Up
              </button>
            </a>
            <br />
            <a href="https://5yyt5ycllb1wx5gmst.com/0whs" target="_blank">
              <button className="button-26">
                Play Gates Of Olympus in Mostbet
              </button>
            </a>
          </div>
        </div>
        <div className="image-container center">
          <img src={img9} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Info;
