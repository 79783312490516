import React, { useState } from "react";
import "./Header.scss";

import logo from "../../assets/logo70.png";
import arrow from "../../assets/drop-down-arrow.png";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="header">
      <div className="header-cont center">
        <a href="/" id="logo">
          <img src={logo} alt="logo" />
        </a>
        <nav>
          <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul className={menuOpen ? "open" : ""}>
            <li>
              <a href="/">Gates of Olympus</a>
            </li>
            <li>
              <a href="/ReviewsInfo">Reviews</a>
            </li>
            <li>
              <a href="/Strategy">Strategy and tactics</a>
            </li>
            <li>
              <a href="/Download">Download</a>
            </li>
            <li>
              <div class="dropdown">
                <a href="/" class="dropbtn">
                  Where to play?
                  <img src={arrow} alt="arrow" className="arrow" />
                </a>
                <div class="dropdown-content">
                  <a href="/Info1Win">Play Gates of Olympus 1win</a>
                  <a href="/InfoPin">Play Gates of Olympus Pin-Up</a>
                  <a href="/InfoMost">Play Gates of Olympus Mostbet</a>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
