import React from "react";
import "./Reviews.scss";
import img from "../../assets/most1.webp";
import img1 from "../../assets/rew.webp";
import img2 from "../../assets/rew1.webp";
import img3 from "../../assets/rew2.webp";
import img4 from "../../assets/rew3.webp";
import img5 from "../../assets/rew4.webp";
import img6 from "../../assets/rew5.webp";
import img7 from "../../assets/rew6.webp";
import img8 from "../../assets/rew8.webp";
import img9 from "../../assets/rew8.webp";
import img10 from "../../assets/rew9.webp";
import img11 from "../../assets/rew9.webp";
import img12 from "../../assets/rew11.webp";
import img13 from "../../assets/rew12.webp";
import img14 from "../../assets/rew13.webp";
import img15 from "../../assets/rew14.webp";
import img16 from "../../assets/rew15.webp";
import img17 from "../../assets/rew16.webp";
import img18 from "../../assets/rew17.webp";
import img19 from "../../assets/rew18.webp";
import img20 from "../../assets/rew19.webp";

const ReviewsInfo = () => {
  return (
    <>
      <div className="text-box-big center">
        <h1>Reviews of the game Gates of Olympus</h1>
        <img src={img} alt="" />
        <p>
          Welcome to our player reviews section about "Gates of Olympus"! We
          know that choosing the right online slot can be difficult, especially
          among the many games. Here we have collected the opinions of real
          players to help you make an informed choice. "Gates of Olympus" is a
          unique slot, loved by many for its impressive graphics and bonuses.
          But what do the players themselves say? How does it feel to open the
          gates of Olympus, stand in front of the gods and win this exciting
          online slot? Immerse yourself in the world of "Gates of Olympus" with
          our player reviews and find out firsthand why this slot is so popular.
        </p>
        <div className="buttons-center center">
          <a href="https://1wauah.xyz/#79x5" target="_blank">
            <button className="button-26">Play Gates Of Olympus in 1win</button>
          </a>

          <br />
         
   <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button className="button-26">
              Play Gates Of Olympus in Mostbet
            </button>
          </a>
          <br />
            <a href="https://5yyt5ycllb1wx5gmst.com/0whs" target="_blank">
            <button className="button-26">
              Play Gates Of Olympus in Mostbet
            </button>
          </a>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>
                Review: Playing Gates of Olympus is just an amazing experience!
              </h2>
              <p className="review-user">
                <span className="Username">Prohor Semyonov</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                Gates of Olympus evokes pleasant impressions. Great graphics,
                interesting plot, although I would like more bonus games.
                Frequent small winnings keep the interest in the game alive, but
                still I expected more. I hope the big wins are still ahead!
              </p>
            </div>
            <div className="colum-img">
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>Feedback: Gates of Olympus is a pleasant experience</h2>
              <p className="review-user">
                <span className="Username">Tom Brown</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                Playing Gates of Olympus is just an amazing experience! From the
                very first minutes, I was fascinated by the wonderful graphics
                and sound effects. And when I started winning, I realized that
                this is exactly what I was looking for. I managed to hit a big
                jackpot, which was a pleasant surprise. I am very glad that
                there are so many bonus rounds in this slot. I recommend it to
                all fans of online casinos!
              </p>
            </div>
            <div className="colum-img">
              <img src={img2} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>Review: I have already managed to win big several times</h2>
              <p className="review-user">
                <span className="Username">Arthur Wolf</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                Gates of Olympus is simply breathtaking! Graphics, music,
                gameplay - everything is at the highest level. I've already
                managed to win big several times, it's just great!
              </p>
            </div>
            <div className="colum-img">
              <img src={img3} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>Review: Be sure to try it, you won't regret it!</h2>
              <p className="review-user">
                <span className="Username">Mary Williams</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                Gates of Olympus is a magical world of Greek gods! Incredible
                graphics, sound and chances of winning are all on top. Be sure
                to try it, you won't regret it!
              </p>
            </div>
            <div className="colum-img">
              <img src={img4} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>Review: I am absolutely delighted with Gates of Olympus!</h2>
              <p className="review-user">
                <span className="Username">Mike Huan</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                I am absolutely delighted with Gates of Olympus! This slot
                simply fascinates with its atmosphere and detail. The winnings
                here are quite frequent, and I have already received large sums
                several times. Of all the slots I've played, Gates of Olympus
                definitely stands out. Difficulty levels and bonus rounds keep
                you in suspense and don't let you get bored. Definitely
                recommend!
              </p>
            </div>
            <div className="colum-img">
              <img src={img5} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>
                Review: Playing Gates of Olympus is like a real journey into the
                world of ancient Greek mythology
              </h2>
              <p className="review-user">
                <span className="Username">Anna De Ray</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                Playing Gates of Olympus is like a real journey into the world
                of ancient Greek mythology. The magnificent design and detailing
                of each element, the background music that takes you to the top
                of Olympus, and the interesting mechanics of the game create a
                unique atmosphere. I like the bonus rounds, each of which offers
                new and interesting opportunities to win. I have already managed
                to win several times, and each time it causes a rush of
                adrenaline and joy. And in general, the game is addictive and
                does not let you get bored. Gates of Olympus is more than just a
                game. It's a whole atmosphere that draws you in from the first
                minute. I recommend it to anyone who wants to take a break and
                plunge into the world of gambling!
              </p>
            </div>
            <div className="colum-img">
              <img src={img6} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>
                Review: I especially liked the design of the game, which takes
                you right to Olympus
              </h2>
              <p className="review-user">
                <span className="Username">Henry Ford</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                After playing a few games at Gates of Olympus, I can say that
                the slot is decent. I especially liked the design of the game,
                which takes you straight to Olympus. Winnings occur quite
                regularly, but most of them were small. The game can get tricky
                if you're looking for big wins, but overall, it's an
                entertaining pastime. It would be great if the bonus games were
                a little more diverse, but overall, I'm happy
              </p>
            </div>
            <div className="colum-img">
              <img src={img7} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>
                Review: The Gates of Olympus game is attractive for its graphics
                and sound
              </h2>
              <p className="review-user">
                <span className="Username">Rafil Muller</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                The Gates of Olympus game is attractive for its graphics and
                sound. The plot of the game is also interesting, especially if
                you are interested in Greek mythology. But as for the winnings,
                I had several small ones and none large ones. Maybe I just
                wasn't lucky enough, but I expected more from the game.
              </p>
            </div>
            <div className="colum-img">
              <img src={img8} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>
                Review: We have already managed to win quite large sums several
                times
              </h2>
              <p className="review-user">
                <span className="Username">Antony Jr.</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                I've been playing Gates of Olympus for several weeks now and I
                can say that this is one of the best slots I've ever played. The
                graphics and sound are top notch, and the gameplay is just
                addictive. We have already managed to win quite large sums
                several times. All in all, a great game!
              </p>
            </div>
            <div className="colum-img">
              <img src={img9} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>Review: I play Gates of Olympus and get high!</h2>
              <p className="review-user">
                <span className="Username">Lily Riley</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                I'm playing Gates of Olympus and getting high! Great graphics,
                exciting bonuses. I have already won large sums several times. I
                recommend it to everyone!
              </p>
            </div>
            <div className="colum-img">
              <img src={img10} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>
                Feedback: At Gates of Olympus, I like the graphics and the
                feeling of being immersed in the world of ancient Greece
              </h2>
              <p className="review-user">
                <span className="Username">Stefan Robinson</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                At Gates of Olympus, I like the graphics and the feeling of
                being immersed in the world of ancient Greece. Frequent small
                wins are uplifting, although there have been no big wins so far.
                An interesting game, worth a try
              </p>
            </div>
            <div className="colum-img">
              <img src={img11} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>
                Review: I have managed to win significant sums several times
              </h2>
              <p className="review-user">
                <span className="Username">May Whitehead</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                It's a pleasure to play Gates of Olympus! The graphics and sound
                are simply at the highest level, and the excitement of the game
                is beyond competition. I managed to win significant sums several
                times, which certainly added to the positive. The bonus rounds
                are especially interesting!
              </p>
            </div>
            <div className="colum-img">
              <img src={img12} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>
                Review: I started playing Gates of Olympus and now I can't stop
              </h2>
              <p className="review-user">
                <span className="Username">Sonny Fisher</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                I started playing Gates of Olympus and now I can't stop.
                Excellent graphics and sound, unique bonuses and, of course, big
                winnings – all this makes this slot unsurpassed. I recommend it
                to anyone who likes gambling!
              </p>
            </div>
            <div className="colum-img">
              <img src={img13} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>
                Review: The game is addictive, and every time I wonder what will
                happen next
              </h2>
              <p className="review-user">
                <span className="Username">Victoria Zhikova</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                I've been playing Gates of Olympus for a couple of weeks now. I
                would like to note that the visual design and sound effects are
                just on top. I managed to win several times, but the amounts
                were not very large. Anyway, the game is addictive, and every
                time I wonder what will happen next
              </p>
            </div>
            <div className="colum-img">
              <img src={img14} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>Review: Incredibly impressed by Gates of Olympus</h2>
              <p className="review-user">
                <span className="Username">Sam Phillips</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                Incredibly impressed with Gates of Olympus. The quality of
                graphics, sound and, most importantly, the excitement of the
                game - everything is on top! I have already managed to hit
                several big jackpots. I recommend it to all lovers of
                excitement!
              </p>
            </div>
            <div className="colum-img">
              <img src={img15} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>
                Feedback: In general, I would recommend this game to those who
                love Greek mythology
              </h2>
              <p className="review-user">
                <span className="Username">Tat Stone</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                I've been playing Gates of Olympus recently, and overall, it was
                a pretty enjoyable experience. The graphics and musical
                accompaniment are very impressive. The biggest prize that I
                managed to get was 500 coins, which pleased me. However, it
                seems to me that bonus rounds don't happen as often as we would
                like. In general, I would recommend this game to those who love
                Greek mythology and beautiful graphics
              </p>
            </div>
            <div className="colum-img">
              <img src={img16} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>Review: I love this game!</h2>
              <p className="review-user">
                <span className="Username">Nate Mason</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                The Gates of Olympus game is simply amazing. It feels like I've
                landed right on Olympus! Every time I win, I feel like a real
                god. I love this game!
              </p>
            </div>
            <div className="colum-img">
              <img src={img17} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>Feedback: I'm looking forward to every new bonus round!</h2>
              <p className="review-user">
                <span className="Username">Nate Mason</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                I'm really enjoying playing Gates of Olympus. This slot
                perfectly combines excellent graphics, sound and the excitement
                of the game. I have already managed to win large sums several
                times, which is especially pleasing. I'm looking forward to
                every new bonus round!
              </p>
            </div>
            <div className="colum-img">
              <img src={img18} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>
                Review: The Gates of Olympus slot did not cause me much
                excitement
              </h2>
              <p className="review-user">
                <span className="Username">Saava Sober</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                The Gates of Olympus slot didn't excite me much, but it couldn't
                disappoint either. The graphic design is really amazing, but I
                haven't had much success in terms of winnings. Bonus rounds seem
                rare to me, although maybe I was just unlucky. I will try again,
                but for now my expectations remain neutral
              </p>
            </div>
            <div className="colum-img">
              <img src={img19} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>
                Feedback: I started playing Gates of Olympus just out of
                curiosity, and now I'm just in love with this game!
              </h2>
              <p className="review-user">
                <span className="Username">Miroslav Kolobo</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                I started playing Gates of Olympus just out of curiosity, and
                now I'm just in love with this game! From the very beginning, I
                was struck by the great graphics and musical accompaniment,
                which makes me feel like I'm on top of Olympus. Playing, I feel
                an indescribable feeling, as if I really am in the world of the
                gods. In addition, I am pleased with the frequent winnings. I've
                already hit the jackpot several times, and it's really
                uplifting! The bonus rounds are also very interesting and
                varied. They add a thrill to the game and make it even more
                exciting. I recommend Gates of Olympus to anyone who is looking
                for an interesting and exciting slot to play!
              </p>
            </div>
            <div className="colum-img">
              <img src={img20} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row-r center">
        <div className="contein">
          <div className="row-txt">
            <div className="colum">
              <h2>
                Feedback: I've tried playing Gates of Olympus, and my
                impressions are mixed
              </h2>
              <p className="review-user">
                <span className="Username">Philip Cat</span>,
                <span className="date">05-25-2023</span>
              </p>
              <p>
                I've tried playing Gates of Olympus, and my impressions are
                mixed. Externally, the game looks great, and I was impressed by
                the level of detail. However, the mechanics of the game seemed a
                bit monotonous to me. Maybe I just didn't quite understand all
                its nuances. In any case, I don't consider the time spent
                playing the game to be lost.
              </p>
            </div>
            <div className="colum-img">
              <img src={img} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewsInfo;
