import React from "react";
import "./Info.scss";
import img from "../../assets/most1.webp";
import img2 from "../../assets/mostnew.jpg";
import img3 from "../../assets/most3.webp";

const InfoMost = () => {
  return (
    <>
      <div className="right-image center">
        <div className="text-box center">
          <h1>Play Gates Of Olympus in Mostbet casino</h1>
          <p>
            Welcome to the lands of the ancient gods and heroes! The Gates of
            Olympus slot opens the gates to the world of Greek mythology, where
            exciting adventures await you. Here you can meet the mighty Zeus,
            who, if you are lucky, will bring huge multipliers up to 500x!
          </p>
          <p>
            A special feature of the game is the Play Anywhere mechanics, which
            means winning if there are 8+ identical symbols on the screen. Gates
            of Olympus also has unique features such as Ante Bet and Tumble,
            which make the game even more exciting.
          </p>
          <p>
            Mostbet online casino offers you to try Gates of Olympus right now.
            Here you will find not only this slot, but also many other games for
            every taste. Sign up, get your welcome bonus and go on a trip to
            Olympus!
          </p>
          <div className="button-large center">
            <a href="https://5yyt5ycllb1wx5gmst.com/0whs" target="_blank">
              <button className="button-26">
                Play Gates Of Olympus in Mostbet
              </button>
            </a>
          </div>
        </div>
        <div className="image-container center">
          <img src={img} alt="" />
        </div>
      </div>
      <div className="text-box-big center">
        <h1>Registration in Mostbet for Gates Of Olympus</h1>
        <img className="big-img" src={img2} alt="" />
        <ul className="number-list-m">
          <li>Going to the site</li>
          <p>
            Go to the official Mostbet website. The main page of the casino will
            open in front of you. In the upper right corner, find and click the
            "Register" button.
          </p>
          <li>Data entry</li>
          <p>
            In the window that opens, enter the necessary information: your
            email, password and country of residence. Make sure that you entered
            all the data correctly and remembered the password.
          </p>
          <li>Confirmation of registration</li>
          <p>
            After filling out the form, click the "Register" button. An email
            with a link to confirm registration will be sent to the email
            address you specified. Follow this link to activate your account.
          </p>
          <li>Log in to your account</li>
          <p>
            After activating your account, log in using your email and password.
            Go to the Casino section to see the list of available games.
          </p>
          <li>The beginning of the game at Gates of Olympus</li>
          <p>
            In the search box, type "Gates of Olympus" and press Enter. Click on
            the game icon to start playing.
          </p>
          <li>Topping up your account and receiving a bonus</li>
          <p>
            Before you start playing for real money, you need to top up your
            account. To do this, go to the "Checkout" section. Also, don't
            forget to get your welcome bonus!
          </p>
        </ul>
        <p>
          Following these simple steps, you will be able to register on the
          Mostbet website and start playing Gates of Olympus. Good luck in the
          game!
        </p>
        <div className="button-large center">
          <a href="https://5yyt5ycllb1wx5gmst.com/0whs" target="_blank">
            <button className="button-26">
              Play Gates Of Olympus in Mostbet
            </button>
          </a>
        </div>
      </div>
      <div className="text-box-pig center">
        <h1>Advantages of Mostbet for playing Gates of Olympus</h1>
        <img className="big-img" src={img3} alt="" />
        <ul className="list-cir-o">
          <li>
            Wide selection of slots
            <br />
            Mostbet online casino prides itself on its extensive range of games,
            and Gates of Olympus is one of the bright stars in its collection.
            Here you will find a rich selection of other slots that will satisfy
            the tastes of any player.
          </li>
          <li>
            Bonuses and promotions
            <br />
            Mostbet constantly conducts various promotions and offers
            interesting bonuses for its players. For example, a welcome bonus
            that can be used in any games, including Gates of Olympus. And
            thanks to the loyalty system, each bet becomes even more profitable!
          </li>
          <li>
            Reliability and safety
            <br />
            Mostbet is a licensed casino that uses modern data encryption
            technologies to ensure the security of all transactions. So players
            can be confident in protecting their funds and personal information.
          </li>
          <li>
            Professional support
            <br />
            In case of any questions or problems, the Mostbet support team is
            always ready to help. They are available 24/7 and ready to help with
            any issues, from registration to withdrawal of winnings.
          </li>
          <li>
            The convenience of the game
            <br />
            With Mostbet, you can enjoy Gates of Olympus anytime, anywhere. The
            online casino is available on various platforms, including PCs,
            mobile phones and tablets, which makes the game even more
            convenient. Try it now and discover all the benefits of playing
            Mostbet!
          </li>
        </ul>
        <div className="button-large center">
          <a href="https://5yyt5ycllb1wx5gmst.com/0whs" target="_blank">
            <button className="button-27">
              Play Gates Of Olympus in Mostbet
            </button>
          </a>
        </div>
      </div>
    </>
  );
};

export default InfoMost;
