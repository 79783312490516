import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import HomePage from "./page/HomePage";
import Strategy from "./page/Strategy";
import Download from "./page/Download";
import Info1Win from "./page/Info1win";
import InfoPin from "./page/Pinup";
import InfoMost from "./page/Mostbet";
import ReviewsInfo from "./page/Reviews";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Strategy" element={<Strategy />} />
          <Route path="/Download" element={<Download />} />
          <Route path="/Download" element={<Download />} />
          <Route path="/Info1Win" element={<Info1Win />} />
          <Route path="/InfoPin" element={<InfoPin />} />
          <Route path="InfoMost" element={<InfoMost />} />
          <Route path="ReviewsInfo" element={<ReviewsInfo />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
